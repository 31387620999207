.themeblock {
    &__all {
        @include tab() {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-top: 15px;
        }
    }
    &__body {
        max-width: 1098px;
        max-height: 140px;
        background: $color_nativno_7;
        border-radius: 20px;
        margin-bottom: 30px;
        position: relative;
        cursor: pointer;
        

       
        @include media() {
            min-width: 290px;
            min-height: 204px;
            margin-bottom: 10px;

             &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 20px;
            padding: 1px;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.13) 0%,
                rgba(255, 255, 255, 0.0169) 21.88%,
                rgba(255, 255, 255, 0.0467187) 53.65%,
                rgba(255, 255, 255, 0.1014) 78.12%,
                rgba(255, 255, 255, 0) 92.19%,
                rgba(255, 255, 255, 0) 100%
            );
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;

            @include media() {
                border-radius: 20px;
            }
        }

        }
        @include tab() {
            width: 290px;
            max-height: 204px;
            margin-bottom: 10px;

        }
    }

    // &__img {

    // }

    &__item {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        @include media() {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &__item-mob {
        display: flex;
        margin-bottom: 15px;
    }

    &__info {
        width: 100%;
    }

    &__header-title {
        max-width: 580px;
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        // line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 20px;
        margin-top: -7px;

        @include media() {
            font-size: 16px;
            line-height: 15px;
            // padding-right: 320px;
            margin-bottom: 7px;
        }
    }

    &__header-href {
        color: $color_nativno_telegram;
        font-weight: 500;
        font-size: 20px;
        line-height: 19px;
        margin-right: 29px;
        display: flex;
        align-items: center;
        cursor: pointer;

        @include media() {
            font-size: 12px;
            line-height: 11px;
            font-weight: 500;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-top: 27px;

        @include media() {
            display: flex;
            flex-direction: column;
            margin: 15px;
        }
    }

    &__img {
        width: 143px;
        height: 100px;
        border-radius: 5px;
        margin: 20px;
        @include media() {
            width: 123px;
            height: 86px;
            margin: 0;
            margin-left: 15px;
        }
    }

    &__arrow {
        margin-left: 24px;
        margin-top: 3px;

        @include media() {
            display: none;
        }
    }

    &__arrow-mob {
        display: none;
        @include media() {
            display: inline-block;
            margin-left: 11px;
        }
    }

    &__chat {
        margin-bottom: -7px;
    }

    &__like {
        margin-bottom: -7px;
    }

    &__data {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-right: 40px;
        text-align: center;

        @include media() {
            display: none;
        }
    }

    &__data-mob {
        display: none;

        @include media() {
            display: block;
            width: 100%;
        }
    }

    &__data-clm {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    &__data-title {
        font-size: 16px;
        line-height: 15px;
        color: $color_nativno_6;
        margin-bottom: 10px;

        @include media() {
            font-size: 10px;
            line-height: 9px;
            font-weight: 400;
            text-align: center;
        }
    }

    &__data-num {
        font-size: 18px;
        line-height: 17px;
        font-weight: bold;

        @include media() {
            font-size: 12px;
            line-height: 11px;
            text-align: center;
        }
    }

    &__footer-mob {
        display: none;

        @include media() {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-end;
            margin-top: -10px;
            margin-bottom: 15px;
        }
    }

    &__no-data {
        @include media() {
            display: none;
        }

        // svg {
        //     width: 14px;
        //     margin-right: 8px;
        //   }
    }

    &__no-data-mob {
        display: none;
        @include media() {
            display: block;
            width: 100%;
        }
    }
}

.border {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.0169) 21.88%,
        rgba(255, 255, 255, 0.0467187) 53.65%,
        rgba(255, 255, 255, 0.1014) 78.12%,
        rgba(255, 255, 255, 0) 92.19%,
        rgba(255, 255, 255, 0) 100%
    );
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    padding: 1px;
    border-radius: 20px;
    height: 140px;
    margin-bottom: 30px;

   
    @include tab() {
        background: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

    }

    @include media() {
        // margin-bottom: 3px;
        height: 100%;
        margin-top: 15px;
        margin-bottom: -10px;
    }
   
}

.border-vertical {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.0169) 21.88%,
        rgba(255, 255, 255, 0.0467187) 53.65%,
        rgba(255, 255, 255, 0.1014) 78.12%,
        rgba(255, 255, 255, 0) 92.19%,
        rgba(255, 255, 255, 0) 100%
    );
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    padding: 1px;
    border-radius: 20px;
    height: 246px;
    margin-bottom: 30px;

    @include tab() {
   height: 100%;
    }

    @include media() {
   height: 100%;
    margin-top: 15px;
    border-radius: 12px;
    }
}


