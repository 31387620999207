.placement {

  

  &__inner {
    margin-top: 50px;
    margin-bottom: 50px;

    @include media() {
      margin-top: 15px;
    }
    
  }

  // &__inner-slaid {
  //   width: 1400px;
  //   margin: 0 auto;
   
  // }


  &__body {
    position: relative;
    height: 590px;
    // max-width: 534px;
    background-color: $color_nativno_7;
    border-radius: 20px 20px 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 15px;
    max-width: 578px;

    @include media() {
      height: 511px;
    }
  }
  
  &__svg {
    position: absolute;
    bottom: 0px;
    left: -15px;
    
  }


  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: $color_nativno_telegram;
    padding: 20px;

    @include media() {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__img{
    width: 100%;
    height: 329px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $color_nativno_4;
    padding: 20px;
    min-height: 90px;

    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;

    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;

    @include media() {
      font-size: 12px;
      line-height: 16px;
    }

  }

  &__text-noimg{
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $color_nativno_4;
    padding: 20px;
    min-height: 90px;

    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;

    display: -webkit-box;
    -webkit-line-clamp: 22;
    -webkit-box-orient: vertical;

    @include media() {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__footer {
    height: 48px;
    border-top: solid 1px $color_nativno_8;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &-data {
      margin-left: 11px;
      display: inline;
    }

    &-data-err {
      display: flex;
    }

  }

  @include media() {
    // height: 54px;
  }

  
}

.ttt{
  display: flex;
}
