@import "mixins.scss";
@import "fonts.scss";
@import "colors.scss";
@import "login.scss";
@import "card.scss";
@import "progress.scss";
@import "parameter.scss";
@import "top-content.scss";
@import "infoblock.scss";
@import "tooltip.scss";
@import "auditory.scss";
@import "snstats.scss";
@import "pie-diagram.scss";
@import "not-found.scss";
@import "loader.scss";

@import "themeblock.scss";
@import "creative-comment.scss";
@import "placement.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

a {
  text-decoration: none;
  color: $color_nativno_4;
}

.container {
  width: 1098px;

  @include media() {
    min-width: 290px;
    width: 90%;
  }
}

.container-all {
  width: 1098px;
  margin: auto;

  @include media() {
    min-width: 290px;
    width: 90%;
  }

  @media only screen and (max-width: 1152px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 360px) {
    font-size: 12px;
  }
}

.bg {
  // background-image: url("./../svg/content_bg.svg");
  background-image: url("./../svg/mainbg.svg");
  background-size: cover;
  background-color: $color_nativno_3;
  background-position: bottom;

  &__creative {
    background-image: url(./../svg/creativbg.svg);
    background-size: cover;
    width: 100%;
  }
}

.main {
  width: 100%;
  min-height: 100vh;
  background-color: $color_nativno_3;
  color: $color_nativno_4;
  position: relative;

  &__header {
    background-color: $color_nativno_5;
    height: 80px;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    line-height: 16.92px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media() {
      height: 40px;
    }

    &-logo {
      cursor: pointer;

      @include media() {
        width: 90px;
        height: 18px;
      }
    }
    // it
    &-title {
      color: $color_nativno_4;
      font-size: 23px;
      line-height: 22px;
      margin: 0px 15px;
      @include media() {
        font-size: 11px;
        line-height: 10px;
        margin: 0px -5px;
      }
    }

    &-titleblock {
      @include media() {
        font-size: 10px;
        line-height: 9px;
        margin-right: 15px;
      }
    }
    // it f
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-contact {
      display: flex;
      align-items: center;

      div:first-child {
        margin-right: 30px;
      }

      &-phone-image {
        display: none;
      }

      @include media() {
        &-phone {
          display: none;
        }
        &-phone-image {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-email {
          font-size: 12px;
          line-height: 11.28px;
          margin-right: 38px;
        }
      }
    }
  }
}

.display {
  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    &-height {
      align-items: stretch;
    }
    &-second {
      margin-top: 15px;
    }
    @include media() {
      justify-content: space-around;
    }
  }
}

.slider-source {
  .slick-slide {
    // width: 534px !important;
    // margin-right: 30px !important;
  }

  .slick-slide.slick-cloned {
    // display: none;
  }
}

// .slick-track {
//   display: flex !important;
// }

// .slick-slide {
//   height: inherit !important;
// }

.slider-arrow-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  position: absolute;
  transform: translate(0, -50%);
  background-color: $color_nativno_7;
  cursor: pointer;

  @include media() {
    width: 30px;
    height: 30px;
  }

  svg {
    stroke: $color_nativno_8;

    @include media() {
      width: 16px;
      height: 16px;
    }
  }

  @media (pointer: fine) {
    &:hover:not(.slider-arrow-btn__disabled) {
      background-color: $color_nativno_telegram;

      svg {
        stroke: $color_nativno_4;
      }
    }
  }

  &:active:not(.slider-arrow-btn__disabled) {
    background-color: $color_nativno_telegram;

    svg {
      stroke: $color_nativno_4;
    }
  }
  &__next {
    top: 50%;
    // right: calc(-65px);
    right: calc(-65px - 59px);
    // &:hover:not(.slider-arrow-btn__disabled) {
    //   background-color: $color_nativno_telegram;
    // }
    

    

    @media only screen and (min-width: 610px) and (max-width: 650px) {
      right: 50px;      
    }

    @media only screen and (min-width: 1152px) {
      right: -20px;
    }
    @media only screen and (min-width: 1280px) {
      right: -40px;
    }

    @media only screen and (min-width: 1340px) {
      right: calc(-65px - 59px);
      // right: 135px;
    }

    @include media() {
      right: 0px;
      z-index: 2;

      
    }

    svg {
      margin-right: -3px;

      @include media() {
        margin-right: -1px;
        margin-top: 0;
      }
    }
  }
  &__prev {
    top: 50%;
    // left: calc(-65px);
    left: calc(-65px - 59px);
    z-index: 1;
   

    @media only screen and (min-width: 1152px) {
      left: -20px;
    }
    @media only screen and (min-width: 1280px) {
      left: -40px;
    }
    @media only screen and (min-width: 1340px) {
      left: calc(-65px - 59px);
    }

    @include media() {
      left: -15px;
      z-index: 2;
    }

    svg {
      transform: rotate(180deg);
      margin-left: -3px;

      @include media() {
        margin-left: -1px;
        margin-top: 0;
      }
    }
  }

  &__disabled {
    background-color: $color_nativno_7;
    cursor: not-allowed;

    svg {
      stroke: $color_nativno_8;
    }
  }
}

.slick-slide > div {
  margin: 0 30px;

  @include media() {
    margin: 0 20px;
    // margin: 0;
    // margin: auto;
    // margin: 0 5%;
  }

  @media only screen and (min-width: 730px) and (max-width: 1152px) {
    margin: 0 20px;
  }
}

.slick-list {
  margin: 0 -30px;

  @include media() {
    margin: 0 -20px;
    // margin: 0;
    // margin: auto;
    // margin: 0 -5%;
  }

  @media only screen and (min-width: 730px) and (max-width: 1152px) {
    // margin: auto;
    margin: 0 -20px;
  }
}

ul.slick-dots {
  li {
    margin: 0;

    button:before {
      color: $color_nativno_8;
      font-size: 11px;
    }

    &.slick-active {
      button:before {
        color: $color_nativno_telegram;
        opacity: 1;
      }
    }
  }
}

.slick-slider {
  // width: 125%;
  margin-right: -15px;
}
