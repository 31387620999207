.creativecomment {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 23px;
    margin-bottom: 40px;
    margin-top: 50px;

    @include media() {
      font-size: 14px;
      line-height: 13px;
      margin-bottom: 15px;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__allcomment {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    margin-top: 15px;

    @include media() {
      margin-bottom: 0px;
    }
  }

  &__body {
    position: relative;
    width: 470px;
    height: 100%;
    background-color: $color_nativno_7;
    border-radius: 20px 20px 20px 0;
    padding: 10px;

    @include media() {
      width: 240px;
      height: 100%;
    }
  }

  &__svg {
    position: absolute;
    // top: 170px;
    // left: -15px;
    bottom: 0;
    right: 100%;
  }

  &__name {
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    color: $color_nativno_telegram;
    padding: 15px 15px 7px 15px;

    @include media() {
      font-size: 12px;
      line-height: 11px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    color: $color_nativno_4;
    padding: 0px 15px;

    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // margin-right: 20px;

    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;

    @include media() {
      font-size: 10px;
      line-height: 13px;
      -webkit-line-clamp: 11;
    }
  }

  &__down {
    padding: 7px 15px;
  }

  &__avt {
    position: relative;
    margin-right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: $color_nativno_6;

    @include media() {
      width: 35px;
      height: 35px;
    }
  }
}
