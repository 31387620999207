.parameter {
  display: flex;
  flex-direction: column;

  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media() {
      font-size: 1em;
      // font-size: 12px;
      font-weight: 700;
      // line-height: 11.28px;
    }

    &__value {
      font-weight: 700;

      &-big {
        font-size: 28px;
        line-height: 26.32px;
        margin-top: 15px;

        @include media() {
          font-size: 14px;
          line-height: 13.16px;
          margin-top: 8px;
        }
      }

      &-medium {
        @include media() {
          margin-top: 7px;
        }
      }

      &-small {
        font-size: 22px;
        line-height: 20.68px;
        margin-top: 15px;

        @include media() {
          font-size: 14px;
          line-height: 13.16px;
          margin-top: 8px;
        }
      }
    }
    &__icon {
      &-big {
        width: 64px;
        height: 64px;

        @include media() {
          width: 24px;
          height: 24px;
        }
      }
      &-medium {
        width: 24px;
        height: 24px;

        @include media() {
          width: 1.3em;
          // width: 16px;
          // height: 16px;
        }
      }
      &-small {
        width: 40px;
        height: 40px;

        @include media() {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    font-weight: 700;

    @include media() {
      align-items: center;
    }

    &__value {
      font-size: 42px;
      line-height: 39.49px;

      @include media() {
        font-size: 20px;
        line-height: 18.8px;
      }

      &-big {
        font-size: 86px;
        line-height: 80.86px;
        max-width: 100px;

        @include media() {
          font-size: 46px;
          line-height: 43.25px;
          display: flex;
         
          justify-content: center;
        }
      }
      &-mob {
        font-size: 20px;
      }
    }

    &__text {
      font-size: 22px;
      line-height: 20.68px;
      white-space: nowrap;
      margin-top: 8px;

      @include media() {
        font-size: 10px;
        line-height: 9.4px;
      }

      &-big {
        @include media() {
          font-size: 14px;
          line-height: 13.6px;
          font-weight: 700;
        }
      }
    }

    &-row {
      flex-direction: row;
      align-self: flex-start;

      .parameter-text__text {
        margin-right: 10px;
        margin-top: 0;
      }
    }
  }

  &-posticon {
    display: flex;
  }
}
