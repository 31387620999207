.tooltip {
  &-pie {
    font-weight: 700;
    font-size: 18px;
    line-height: 16.92px;
    display: flex;
    opacity: 0;
    animation: showTooltip 1.5s ease 0s forwards;
    z-index: 2;

    @include media() {
      font-size: 10px;
      line-height: 9.4px;
    }

    &__num {
      border-radius: 10px 0px 0px 10px;
      padding: 10px 8px;
      background-color: $color_nativno_5;

      @include media() {
        border-radius: 5px 0px 0px 5px;
        padding: 5px;
      }

      span {
        color: $color_nativno_telegram;
      }
    }

    &__value {
      color: $color_nativno_5;
      background-color: $color_nativno_4;
      border-radius: 0px 10px 10px 0px;
      padding: 10px;
      position: relative;

      @include media() {
        border-radius: 0px 5px 5px 0px;
        padding: 5px;
      }
      
    }

    &__pointer {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 17px 0 0 12px;
      border-color: #fff9 #0000 #0000 #0000;
      left: 7px;
      top: 38px;
      transform: skew(48deg, 330deg) rotate(27deg);

      @include media() {
        border-width: 10px 0 0 8px;
        top: 20px;
      }

      &-left {
        transform: skew(48deg, 319deg) rotate(63deg) scale(-1, 1);
        top: 35px;

        @include media() {
          top: 18px;
        }
      }
    }

    &__num-block-icon {
      padding: 0;

      @include media() {
        padding: 0px;
      }
    }
    &__num-icon {
      background-size: auto;
      background-position: center;
      background-repeat: no-repeat;
      width: 37px;
      height: 37px;

      @include media() {
        width: 10px;
        height: 10px;
        background-size: contain;
      }
    }
    &__ok {
      background-image: url("./../svg/mini-ok.svg");
    }
    &__vk {
      background-image: url("./../svg/mini-vk.svg");
    }
    &__fb {
      background-image: url("./../svg/mini-fb.svg");
    }
    &__yt {
      background-image: url("./../svg/mini-yt.svg");
    }
    &__tg {
      background-image: url("./../svg/mini-tg.svg");
    }
    &__zen {
      background-image: url("./../svg/mini-zen.svg");
    }
    &__over {
      background-image: url("./../svg/mini-over.svg");
    }
    &__insta {
      background-image: url("./../svg/mini-insta.svg");
    }
    &__tiktok {
      background-image: url("./../svg/mini-tiktok.svg");
    }
  }

  &-sn-coverage {
    background-color: rgba(0, 0, 0, 0.85);
    padding: 8px 10px;
    text-align: center;
    font-size: 12px;
    line-height: 15.6px;
    color: $color_nativno_4;

    &__date {
      color: $color_nativno_2;
    }
    &__coverage,
    &__posts {
      span {
        color: $color_nativno_6;
        margin-right: 5px;
      }
    }
  }
}

.too {
  top: 10px;
}

@keyframes showTooltip {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}