.top-content {
  // height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  padding-top: 60px;

  @include media() {
    padding-bottom: 40px;
    padding-top: 10px;
  }



  .container {
    // padding-top: 60px;
  }

  &__background {
    height: 100%;
    max-width: 100%;
    position: absolute;
  }

  &__main {
    display: flex;
    align-items: center;
    flex-direction: row;

    @include media() {
      justify-content: center;
    }

  

    &-column {
      width: 50%;

      @include media() {
        width: 100%;
        margin-bottom: 20px;
      }
            
    }
  }
  &__pie {
    width: 600px;
    // height: 416px;
    position: relative;
    display: flex;
    justify-content: center;

    @include media() {
      width: 100%;
    }

   

    .recharts-responsive-container {
      width: 628px;
      height: 416px;
      // margin-top: -90px;
    }
  }

  &-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: 260px;
    // height: 260px;
    background: #1b1d27;
    border-radius: 50%;
  }

  @keyframes fadeinside {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &-pie-data-back {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #191d27;
    border-radius: 50%;
    width: 240px;
    height: 240px;
    animation: fadeinside 1s;

    @include media() {
      width: 125px;
      height: 125px;
    }

    
  }

  &-pie-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color_nativno_5;
    border-radius: 50%;
    border: 5px solid $color_nativno_telegram;
    width: 185px;
    height: 185px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // margin-left: -19px;
    // display: none;

    @include media() {
      border: 3px solid $color_nativno_telegram;
      width: 104px;
      height: 104px;
      margin-left: 0;
    }

    &__now {
      background-color: $color_light_20;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      font-size: 40px;
      font-weight: 700;
      line-height: 37.61px;
      color: $color_nativno_2;
      position: absolute;
      // display: flex;
      display: none;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;

      @include media() {
        width: 22px;
        height: 22px;
        font-size: 17px;
        line-height: 15.98px;
        margin-top: 5px;
      }
    }

    &__num {
      font-size: 113px;
      font-weight: 700;
      line-height: 106.24px;

      @include media() {
        font-size: 52px;
        line-height: 48.89px;

        svg {
          width: 40px;
          height: 40px;
        }
      }
    }
    &__theme {
      font-size: 18px;
      font-weight: 700;
      line-height: 16.92px;

      @include media() {
        font-size: 11px;
        line-height: 10.34px;
      }
    }
    &__vertical {
      font-size: 18px;
      font-weight: 700;
      line-height: 16.92px;

      @include media() {
        font-size: 11px;
        line-height: 10.34px;
      }
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include media() {
      margin-top: 15px;
    }
  }

  &__legends {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 76px;


    @include tab() {
      margin-top: 15px;
      justify-content: space-evenly;
      align-content: space-around;
      margin-bottom: 0px;
    }

    &-item {
      display: flex;
      margin-bottom: 40px;
      // margin-right: 50px;
      // width: 218px;
      width: 255px;
      cursor: pointer;
    }

    &-info {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-num {
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      width: 32px;
      height: 32px;
    }
    &-inner {
      border-radius: 3px;
      background-color: $color_nativno_3;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      font-weight: 700;
      font-size: 14px;
      line-height: 13.16px;
    }
    &-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 13.16px;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   
      &:hover {
        // border-bottom: 1px solid $color_nativno_16;
        text-decoration: underline;
      
      }

      &-category {
        font-weight: 500;
        font-size: 16px;
        line-height: 15px;
      }
    }

    &-value {
      font-size: 12px;
      line-height: 11.28px;
      opacity: 0.6;
    }
  }

  &__legends-mobile {
    margin-top: 15px;
    display: flex;
    align-items: center;
    background-color: $color_nativno_5;
    padding: 10px;
    border-radius: 20px;
    width: 100%;

    &-item {
      display: flex;
      width: 100%;
    }

    &-info {
      // width: 180px;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-num {
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      width: 28px;
      height: 28px;
      flex: none;
    }
    &-inner {
      border-radius: 3px;
      background-color: $color_nativno_3;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      font-weight: 700;
      font-size: 14px;
      line-height: 13.16px;
    }
    &-name {
      font-weight: 500;
      font-size: 12px;
      line-height: 11.28px;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-value {
      font-size: 10px;
      line-height: 9.4px;
      opacity: 0.6;
    }

    &-controls {
      display: flex;
      align-items: center;

      svg {
        stroke: $color_nativno_6;
        width: 16px;
        height: 16px;
        stroke-width: 1.5px;
      }

      &-prev {
        transform: scale(-1, 1);
        padding-top: 4px;
      }

      &-next {
        margin-left: 15px;
        padding-top: 4px;
      }
    }
  }

  &__line {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin: 30px 0;

    @include media() {
      margin: 8px 0;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }

  &-detail {
    padding-bottom: 108px;
    padding-top: 0;

    @include media() {
      padding-bottom: 40px;
    }
  }

  &__detail-parameters {
    @include media() {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__categorybody {
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  &__category {
    margin-top: 55px;

    &-legend {
      display: flex;
      flex-direction: column;
    }
  }
}

.maindidal {
   
    @include media() {
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

  

    &-column {
      width: 50%;
    }
}
