.progress {
  background-color: $color_nativno_8;
  width: 173px;
  height: 20px;
  border-radius: 8px;
  position: relative;
  margin-top: 20px;
  
  @include media() {
    height: 13px;
    width: 125px;
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
  }
  @include tab() {
    height: 13px;
    width: 173px;
    // width: 100%;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  &__fill {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color_nativno_telegram;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    
    @include media() {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    
    &_full {
      border-radius: 8px;

      @include media() {
        border-radius: 5px;
      }
    }
  }

  &__value {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 13.16px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media() {
      font-weight: 400;
      font-size: 10px;
      line-height: 9.4px;
    }
  }
}

.progress-city, .progress-interest {
  margin-top: 9px;
  height: 10px;
  // width: 212px;
  width: 100%;
  border-radius: 9px;

  @include media() {
    // width: 110px;
    width: 100%;
  }

  .progress__fill {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;

    @include media() {
      border-radius: 6px;
    }

    &_full {
      border-radius: 9px;

      @include media() {
        border-radius: 6px;
      }
    }
  }
}
.progress-city {
  .progress__fill {
    background-color: $color_nativno_2;
  }
}
.progress-interest {
  .progress__fill {
    background-color: $color_nativno_11;
  }
}