.card {
  position: relative;
  background-color: $color_nativno_7;
  border-radius: 20px;
  padding: 20px;
  color: $color_nativno_4;
  cursor: pointer;

  @include media() {
    padding: 15px;
    border-radius: 10px;
  }

  // background: linear-gradient(180deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.0169) 21.88%, rgba(255, 255, 255, 0.0467187) 53.65%, rgba(255, 255, 255, 0.1014) 78.12%, rgba(255, 255, 255, 0) 92.19%, rgba(255, 255, 255, 0) 100%);
  // -webkit-mask-composite: destination-out;
  // mask-composite: exclude;
  // padding: 1px;
  // border-radius: 20px;


  
  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   border-radius: 20px;
  //   padding: 1px;
  //   background: linear-gradient(
  //     180deg,
  //     rgba(255, 255, 255, 0.13) 0%,
  //     rgba(255, 255, 255, 0.0169) 21.88%,
  //     rgba(255, 255, 255, 0.0467187) 53.65%,
  //     rgba(255, 255, 255, 0.1014) 78.12%,
  //     rgba(255, 255, 255, 0) 92.19%,
  //     rgba(255, 255, 255, 0) 100%
  //   );
  //   -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  //   -webkit-mask-composite: destination-out;
  //   mask-composite: exclude;

  //   @include media() {
  //     border-radius: 10px;
  //   }
  // }

  &__card {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 20px 30px;
    z-index: 1;

    @include media() {
      padding: 15px;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28.6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;

    @include media() {
      
      font-size: 14px;
      line-height: 13.16px;
      // padding-right: 0;
      margin-bottom: 10px;
    }
  }
}
.card-vertical {
  width: 534px;
  height: 246px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  border: 0px solid transparent;

  @media (pointer: fine) {
    &:hover {
      border: 1px solid $color_nativno_telegram;
    }
  }

  @include media() {
    max-width: 330px;
    height: 176px;
    margin-bottom: 0px;
    // margin-top: 15px;
   
  }

  @include tab() {
    min-width: 290px;
    height: 246px;
    // margin-top: 15px;
   
  }

  // @media only screen and (min-width: 3200px) {
  //   width: 100%;
  //   height: 176px;
  // }

  &__date {
    font-size: 14px;
    line-height: 13.16px;
    color: $color_nativno_6;
    margin-top: 10px;

    @include media() {
      margin-top: 0;
      font-size: 0.8em;
      // font-size: 10px;
      // line-height: 9.4px;
      align-self: flex-start;
    }
    @include tab() {
      margin-left: 25px;
    }
  }

  &__body {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 100%;
    

    @include media() {
      margin-top: 0px;
      height: 50%;
    }
    @include tab() {
      margin-top: 10px;
      height: 100%;
      margin-bottom: 18px;
    }
  }

  &__body-phone {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include media() {
      // height: 40%;
    }
  }

  &__diagram {
    width: 173px;
    font-size: 13px;

    @include media() {
      // width: 122px;
      width: 100%;
      font-size: 10px;
      line-height: 9.4px;
    }
  }

  &__params {
    margin-left: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    @include media() {
      margin-left: 13px;
    }

    &-second {
      @include media() {
        margin-top: 12px;
      }
    }

    .parameter {
      &-text {
        align-items: center;
        @include media() {
          width: 50%;
          align-items: center;
        }

        &__value {
          font-size: 16px;
          line-height: 15.04px;

          @include media() {
            font-size: 1em; // font-size: 12px;
            font-weight: 700;
            // line-height: 11.28px;
          }
        }

        &__text {
          font-size: 14px;
          line-height: 13.16px;
          font-weight: 400;
          white-space: nowrap;
          margin-top: 5px;
          color: $color_nativno_6;

          @include media() {
            font-size: 10px;
            line-height: 9.4px;
          }
        }
      }
    }
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid $color_nativno_8;
    width: 97%;
    height: 46px;
    margin-top: 20px;

    &-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 15.04px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 14px;
      }

      @include media() {
        font-size: 12px;

        svg {
          width: 14px;
          margin-right: 8px;
        }
      }
    }
    &-info {
      font-size: 14px;
      line-height: 13.16px;
      color: $color_nativno_6;
      margin-top: 11px;

      @include media() {
        font-size: 10px;
        text-align: center;
        margin-top: 1px;
      }
    }

    @include media() {
      width: 90%;
      height: 84px;
      margin-top: 0px;
      margin-left: 10px;
    }
  }

  &__no-datainfo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid $color_nativno_8;
    width: 97%;
    height: 100%;
    margin-top: 0px;
  }
}

.card-now {
  background: radial-gradient(70% 102.03% at 100% 0%, rgba(253, 214, 8, 0.2) 0%, rgba(253, 214, 8, 0) 100%),
    linear-gradient(0deg, #242d3f, #242d3f);

  .card__title {
    padding-right: 40px;
  }

  &__ribbon {
    text-align: center;
    transform: rotate(45deg);
    position: relative;
    background-color: $color_light_20;
    color: $color_nativno_2;
    line-height: 20.68px;
    width: 160px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 22px;
    top: 10px;
    left: 20px;

    @include media() {
      font-size: 1em;
      line-height: 11.28px;
      width: 120px;
      height: 20px;
      top: 10px;
      left: 50px;
    }

    &-wrapper {
      overflow: hidden;
      position: absolute;
      // width: 132px;
      // height: 130px;
      top: 16px;
      right: 20px;

      @include media() {
        top: 8px;
        right: 8px;
      }
    }
  }
}

.card-bordered {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.0169) 21.88%, rgba(255, 255, 255, 0.0467187) 53.65%, rgba(255, 255, 255, 0.1014) 78.12%, rgba(255, 255, 255, 0) 92.19%, rgba(255, 255, 255, 0) 100%);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  padding: 1px;
  border-radius: 20px;

  @include media() {
    border-radius: 10px;
  }

  &__social-examples {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__body {
    background-color: $color_nativno_7;
    color: $color_nativno_4;
    border-radius: 20px;
    padding: 20px;
    
    width: 100%;
    
    margin-bottom: 36px;
    // position: relative;
    //  &:after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   border-radius: 20px;
    //   padding: 1px;
    //   background: linear-gradient(
    //     180deg,
    //     rgba(255, 255, 255, 0.13) 0%,
    //     rgba(255, 255, 255, 0.0169) 21.88%,
    //     rgba(255, 255, 255, 0.0467187) 53.65%,
    //     rgba(255, 255, 255, 0.1014) 78.12%,
    //     rgba(255, 255, 255, 0) 92.19%,
    //     rgba(255, 255, 255, 0) 100%
    //   );
    //   -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    //   -webkit-mask-composite: destination-out;
    //   mask-composite: exclude;
    // }

    

    @include media() {
      border-radius: 20px;
    }

    @include media() {
      border-radius: 10px;
      padding: 15px;
    }
  }

  &__body-tb {
    background-color: $color_nativno_7;
    color: $color_nativno_4;
    border-radius: 20px;
    
    
    width: 100%;
    
    // margin-bottom: 36px;
    
 

    @include media() {
      border-radius: 20px;
    }

    @include media() {
      border-radius: 20px;
      padding: 15px;
    }
  }

  &__vk,
  &__ok,
  &__fb,
  &__yt,
  &__tg,
  &__zen,
  &__over,
  &__insta,
  &__tiktok {
    // width: 100%;
    flex: 1;
    margin-right: 20px;
    max-width: 539px;

    &:last-child {
      margin-right: 0;
    }

    @include media() {
      width: 100%;
      max-width: 100%;
    }
  }

  &__vk &__body {
    background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(39, 135, 245, 0.3) 0%, rgba(39, 135, 245, 0) 100%),
      #222632;
    height: 100%;
  }
  &__ok &__body {
    background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(238, 129, 8, 0.3) 0%, rgba(238, 129, 8, 0) 100%),
      #222632;
    height: 100%;
  }
  &__fb &__body {
    background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(25, 118, 210, 0.3) 0%, rgba(25, 118, 210, 0) 100%),
      #222632;
    height: 100%;
  }
  &__yt &__body {
    background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(255, 0, 0, 0.3) 0%, rgba(255, 0, 0, 0) 100%), #222632;
    height: 100%;
  }
  &__tg &__body {
    background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(15, 171, 238, 0.3) 0%, rgba(15, 171, 238, 0) 100%),
      #222632;
    height: 100%;
  }
  &__zen &__body {
    background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(254, 0, 0, 0.3) 0%, rgba(255, 0, 0, 0) 100%), #222632;
    height: 100%;
  }
  &__over &__body {
    // background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(254, 0, 0, 0.3) 0%, rgba(255, 0, 0, 0) 100%),
    // #222632;
    background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(163, 189, 201, 0.3) 0%, rgba(163, 189, 201, 0) 100%),
      #222632;

    height: 100%;
  }
  &__insta &__body {
    // background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(255, 105, 180, 0.3) 0%, rgba(255, 105, 180, 0) 100%),
    //   #222632;
    background: radial-gradient(
        87.12% 72.27% at 50.1% 0%,
        rgba(249, 87, 28, 0.267857) 0%,
        rgba(237, 1, 85, 0.153) 48.96%,
        rgba(218, 1, 178, 0) 100%
      ),
      linear-gradient(0deg, #222632, #222632);

    height: 100%;
  }
  &__tiktok &__body {
    background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(14, 198, 218, 0.3) 0%, rgba(32, 169, 187, 0) 100%),
      #222632;
    // background: radial-gradient(87.12% 72.27% at 50.1% 0%, rgba(11, 152, 233, 0.324) 0%, rgba(127, 93, 228, 0.153) 48.96%, rgba(218, 1, 178, 0) 100%), linear-gradient(0deg, #222632, #222632);
    height: 100%;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28.6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;

    @include media() {
      padding-right: 20px;
      font-size: 14px;
      font-weight: 700;
      line-height: 13.16px;
    }
  }

  &__social-examples &__title {
    padding: 0;
    margin-bottom: 10px;

    @include media() {
      margin-top: 10px;
      line-height: 22px;
      font-size: 18px;
    }
  }

  &__example-link {
    background-color: $color_nativno_3;
    border-radius: 20px;
    border: 1px solid #484f66;
    width: 100%;
    padding: 11px 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    & > div {
      white-space: nowrap;
    }
    svg {
      stroke: $color_nativno_6;

      @include media() {
        width: 16px;
        height: 16px;
      }
    }

    @include media() {
      font-size: 14px;
      line-height: 18.2px;
    }

    @media (pointer: fine) {
      &:hover,
      &:active {
        background-color: $color_nativno_2;
        border: 1px solid #dd4242;

        svg {
          stroke: $color_nativno_4;
        }
      }
    }
  }

  &__auditory {
    margin-right: 30px;
    // width: 50%;
    width: 252px;

    @include media() {
      // width: 140px;
      width: calc(50% - 10px);
      margin-right: 10px;
    }

    &-gender {
      width: 534px;

      @include media() {
        width: 100%;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__auditory &__body {
    height: 100%;
  }
  &__sources &__body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__snstats {
    width: 100%;
    height: 334px;
    margin-left: 30px;
    margin-bottom: 80px;

    @include media() {
      min-width: 290px;
      width: 100%;
      height: 247px;
      margin: 0 auto;
    }
  }

  &__creatives {
    &:first-child {
      &:nth-last-child(1) {
        width: 534px;
        margin: auto;
        @include media() {
          width: 290px;
        }
      }
    }

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      @include media() {
        margin-bottom: 10px;
      }
    }
    &-title-title {
      flex: 1;
      font-size: 22px;
      font-weight: 700;
      line-height: 28.6px;

      @include media() {
        font-size: 14px;
        line-height: 13.16px;
      }
    }
    &-title-image {
      margin-right: 15px;

      @include media() {
        margin-right: 10px;
      }

      img {
        width: 20px;
        height: 20px;

        @include media() {
          width: 16px;
          height: 16px;
          border-radius: 5px;
        }
      }
    }
    &-title-num {
      color: $color_nativno_6;
      font-weight: 500;
      font-size: 18px;
      line-height: 16.92px;
      display: flex;
      align-items: center;

      @include media() {
        font-size: 12px;
        line-height: 11.28px;
      }

      span {
        margin: 0 15px;

        @include media() {
          margin: 0 10px;
        }
      }

      svg {
        cursor: pointer;

        @include media() {
          width: 16px;
          height: 16px;
        }
      }
    }
    &-image {
      border-radius: 5px;
      width: 494px;
      height: 494px;
      max-width: 100%;
      max-height: 100%;
      display: flex;
      align-items: center;

      @include media() {
        width: 100%;
        // height: 260px;
        height: auto;
        justify-content: center;
      }

      & img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 5px;
      }
    }
    &-params {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      // margin-top: 20px;

      @include media() {
        font-size: 10px;
        line-height: 13px;
        margin-top: 10px;
      }

      &-second {
        @include media() {
          justify-content: space-around;
        }
      }

      & > div {
        display: flex;
        align-items: center;

        @include media() {
          margin-right: 10px;
          display: flex;
          flex-direction: column;
        }
      }

      svg {
        margin-right: 10px;

        @include media() {
          width: 22px;
          max-height: 12px;
          margin-right: 0;
        }
      }
    }

    &-thumbs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      width: 100%;
      cursor: pointer;

      &-item {
        border-radius: 5px;
        width: 68px;
        height: 68px;
        display: flex;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
        border: 2px solid transparent;
        overflow: hidden;

        @include media() {
          width: 47px;
          height: 47px;
          margin-right: auto;
          margin-left: auto;
        }

        &:first-child {
          margin-left: 0;

          &:nth-last-child(2) {
            margin-left: auto;
          }

          @include media() {
            margin-right: auto;
            margin-left: auto;
          }
        }

        &:last-child {
          margin-right: 0;

          &:nth-child(2) {
            margin-right: auto;
          }

          @include media() {
            margin-right: auto;
            margin-left: auto;
          }
        }

        &:only-child {
          margin-right: auto;
          margin-left: auto;
        }

        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        &_active {
          border: 2px solid #ff4062;
        }
      }
    }
  }

  &__dbl-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 27px;

    @include media() {
      margin-bottom: 15px;
    }

    &-legend {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 12.6px;

      @include media() {
        font-size: 10px;
        line-height: 9px;
      }

      &-fblock {
        width: 16px;
        height: 16px;
        border-radius: 5px;
        border: 1px solid $color_nativno_telegram;
        background-color: $color_nativno_telegram;
        margin-right: 8px;

        @include media() {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
      &-sblock {
        width: 16px;
        height: 16px;
        border-radius: 5px;
        border: 1px solid $color_nativno_13;
        background-color: $color_nativno_13;
        margin-right: 8px;

        @include media() {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }

      &-f {
        margin-right: 20px;
        display: flex;
        align-items: center;

        @include media() {
          margin-right: 10px;
        }
      }
      &-s {
        display: flex;
        align-items: center;
      }
    }
  }
}

.cards__example {
  display: flex;
  justify-content: space-between;
  padding-bottom: 180px;
}

.card-bordered__creatives {
  // margin-right: 30px;

  @include media() {
    // margin-right: 0;
    width: 100%;
  }
}

.card-bordered__sources {
  height: 537px;
  width: 534px;
  // margin-right: 30px;
  margin-top: 50px;

  @include media() {
    height: 313px;
    // min-width: 300px;
    // width: 95vw;
    // max-width: 534px;
    // width: 350px;
    width: 97%;
    // width: calc(100vw/1);
    // margin-left: -10px»»;
    margin-top: 15px;
  }

  @media only screen and (min-width: 730px) and (max-width: 1152px) {
    max-width: 534px;
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-site {
    font-size: 18px;
    font-weight: 700;
    line-height: 23.4px;
    color: $color_nativno_telegram;

    @include media() {
      font-size: 14px;
      line-height: 13.16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
    }
  }
  &-link {
    stroke: $color_nativno_6;
    cursor: pointer;

    @include media() {
      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
  &-info {
    border-left: 3px solid $color_nativno_telegram;
    padding-left: 10px;
    margin-top: 20px;

    @include media() {
      margin-top: 13px;
    }
  }
  &-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include media() {
      font-size: 12px;
      line-height: 14.4px;
    }
  }
  &-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include media() {
      font-size: 12px;
      line-height: 14.4px;
    }
  }
  &-image {
    width: 494px;
    height: 330px;
    margin-top: 20px;
    flex: 1;
    display: flex;
    align-items: flex-end;

    @include media() {
      height: 174px;
      width: 100%;
      justify-content: center;
      margin-top: 13px;
      align-items: flex-end;
    }

    img {
      max-width: 494px;
      max-height: 330px;
      width: 100%;
      border-radius: 5px;

      @include media() {
        max-width: 494px;
        max-height: 174px;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.card-bordered__title-flex {
  display: flex;
}

// .border-info {
//   background: linear-gradient(
//       180deg,
//       rgba(255, 255, 255, 0.13) 0%,
//       rgba(255, 255, 255, 0.0169) 21.88%,
//       rgba(255, 255, 255, 0.0467187) 53.65%,
//       rgba(255, 255, 255, 0.1014) 78.12%,
//       rgba(255, 255, 255, 0) 92.19%,
//       rgba(255, 255, 255, 0) 100%
//   );
//   -webkit-mask-composite: destination-out;
//   mask-composite: exclude;
//   padding: 1px;
//   border-radius: 20px;
//   // height: 260px;

 
//   @include media() {
//      background: none;
//   }
 
// }
