
@mixin media() {
  @media only screen and (max-width: 1153px) {
    @content;
  }
  // @media only screen and (max-width: 1340px) {
  //   @content;
  // }
}

@mixin tab() {
  @media only screen and (min-width: 650px) and (max-width: 1153px) {
    @content;
  }
} 

