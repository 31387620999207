.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  background-color: $color_nativno_3;
  background-image: url("./../svg/mainbg.svg");
  background-size: cover;
  background-color: $color_nativno_3;
  background-position: bottom;


  

  @include media() {
    height: calc(100vh - 40px);
    background-position: right;

  }

  &-card {
    width: 346px;
    height: 263px;
    padding: 20px 30px;
   

    &__title {
      font-size: 22px;
      font-weight: 700;
      line-height: 20.68px;
      text-align: center;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 13.16px;
      text-align: center;
      color: $color_nativno_6;
      margin-top: 5px;
    }

    &__row {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__input {
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 5px;
      padding: 13.5px 15px;
      width: 286px;
      color: $color_nativno_4;
      font-family: TTNorms, Arial, sans-serif;
      font-size: 14px;
      line-height: 13.16px;
      font-weight: 400;

      @include media() {
        width: 260px;
      }

      &::placeholder {
        color: $color_nativno_6;
        font-size: 14px;
        line-height: 13.16px;
        font-weight: 400;
      }

      &:focus {
        outline: none;
      }

      &_error {
        border: 1px solid $color_nativno_2;
      }
    }

    &__input-text-error {
      color: $color_nativno_2;
      font-size: 14px;
      line-height: 16.52px;
      width: 100%;
      margin-top: 9px;
      margin-left: 20px;
     
      @include media() {
        margin-left: 55px;
      }

      @include tab() {
        margin-left: 55px;
      }

      @media only screen and (min-width: 320px) and (max-width: 360px){
        margin-left: 35px;
      }
    }

    &__btn {
      -webkit-appearance: none;
      padding: 15.5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: $color_nativno_telegram;
      border: 0;
      width: 95%;
      color: $color_nativno_4;
      font-family: TTNorms, Arial, sans-serif;
      font-size: 14px;
      line-height: 13.16px;
      font-weight: 700;
      cursor: pointer;

      @media (pointer: fine) {
        &:hover {
          background-color: $color_nativno_15;
        }
      }

      &:disabled, &[disabled] {
        cursor: not-allowed;
      }
    }
  }

  &-card.card-bordered {
    @include media() {
      border-radius: 20px;
    }

    .card-bordered__body {
      @include media() {
        border-radius: 20px;
      }
    }
  }
}